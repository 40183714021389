<template>
  <LiefengContent>
    <template v-slot:title>表单模板库</template>
    <template v-slot:toolsbarRight>
      <Form
        :model="searchForm"
        inline
        label-colon
        class="searchForm"
        :label-width="80"
      >
        <FormItem label="表单名称">
          <Input
            v-model.trim="searchForm.formName"
            style="width: 150px"
          ></Input>
        </FormItem>
        <FormItem label="表单类型">
          <Select v-model="searchForm.templateType" style="width: 150px">
            <OptionGroup label="私有">
              <Option value="1">个人</Option>
            </OptionGroup>

            <OptionGroup label="公开">
              <!-- 注： 如果 templateType为3或4，应为2表示公开-->
              <Option value="3">机构</Option>
              <Option value="4">平台</Option>
            </OptionGroup>
          </Select>
        </FormItem>
        <Button
          type="primary"
          icon="ios-search"
          @click="search"
          style="margin-right: 6px"
          >查询</Button
        >
      </Form>
      <Button
        type="primary"
        @click="
          formDetailVo2 = {};
          addStatus = true;
        "
        icon="ios-add"
        >新增</Button
      >
      <Button v-if="$route.query.path" type="error" style="margin-left: 6px" icon="ios-arrow-back" @click="$router.push({path: $route.query.path,query:{menuId: $route.query.menuId}})">返回</Button>
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :loading="loading"
        :fixTable="true"
        :pagesizeOpts="[20, 30, 50, 100]"
        :curPage="page"
        :total="total"
        :page-size="pageSize"
        @hadlePageSize="hadlePageSize"
      ></LiefengTable>
      <!-- 新增 -->
      <LiefengModal
        title="新增表单"
        :fullscreen="true"
        :value="addStatus"
        @input="addStatusFn"
        class="add"
      >
        <template v-slot:contentarea>
          <AddForm
            v-if="addStatus"
            @saveSuccess="saveSuccess"
            :formDetailVo2="formDetailVo2"
            ref="addForm"
          ></AddForm>
        </template>
        <template v-slot:toolsbar>
          <Button
            @click="
              cancelModelTip = true;
              addStatusFn(false);
            "
            type="info"
            style="margin-right: 10px"
            >取消</Button
          >
          <Button @click="saveAddChange('addForm')" type="primary">保存</Button>
        </template>
      </LiefengModal>
      <!-- 修改 -->
      <LiefengModal
        title="修改表单"
        :fullscreen="true"
        :value="changeStatus"
        @input="changeStatusFn"
        class="add"
      >
        <template v-slot:contentarea>
          <ChangeForm
            v-if="changeStatus"
            @saveSuccess="saveSuccess"
            :infoId="infoId"
            ref="changeForm"
          ></ChangeForm>
        </template>
        <template v-slot:toolsbar>
          <Button
            @click="
              cancelModelTip = true;
              changeStatusFn(false);
            "
            type="info"
            style="margin-right: 10px"
            >取消</Button
          >
          <Button @click="saveAddChange('changeForm')" type="primary"
            >保存</Button
          >
        </template>
      </LiefengModal>
      <!-- 预览 -->
      <LiefengModal
        :title="previeswTitle"
        :fullscreen="true"
        :value="previewStatus"
        @input="previewStatusFn"
      >
        <template v-slot:contentarea>
          <PreviewForm
            :previewFormData="previewFormData"
            v-if="previewStatus"
          ></PreviewForm>
        </template>
        <template v-slot:toolsbar>
          <Button type="primary" @click="previewStatus = false">关闭</Button>
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route("/formindex")
import LiefengContent from "@/components/LiefengContent";
import LiefengModal from "@/components/LiefengModal";
import LiefengTable from "@/components/LiefengTable";
import PreviewForm from "@/views/form/childrens/previewForm";
import AddForm from "@/views/form/childrens/addForm";
import ChangeForm from "@/views/form/childrens/changeForm";
export default {
  data() {
    return {
      buttonRoot:"",
      searchForm: {
        templateType: "1",
      },
      talbeColumns: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "表单编码",
          key: "id",
          minWidth: 120,
          align: "center",
        },
        {
          title: "表单名称",
          key: "formName",
          minWidth: 120,
          align: "center",
        },
        {
          title: "题目数",
          key: "fieldNum",
          width: 140,
          align: "center",
        },
        {
          title: "使用次数",
          key: "citations",
          width: 140,
          align: "center",
        },
        {
          title: "创建人",
          key: "createName",
          minWidth: 120,
          align: "center",
        },
        {
          title: "创建时间",
          key: "gmtCreate",
          width: 180,
          align: "center",
        },
        {
          title: "操作",
          width: 200,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },
                  style: {
                    width: "65px",
                    marginRight: "10px",
                    textAlign: "center",
                  },
                  on: {
                    click: () => {
                      this.previewForm(params.row.id);
                    },
                  },
                },
                "预览"
              ),
              h(
                "Dropdown",
                {
                  props: {
                    transfer: true,
                  },
                },
                [
                  h(
                    "Button",
                    {
                      props: {
                        type: "success",
                        size: "small",
                        icon: "ios-arrow-down",
                      },
                    },
                    "编辑"
                  ),
                  h(
                    "DropdownMenu",
                    {
                      slot: "list",
                    },
                    [
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.infoId = params.row.id;
                              this.changeStatus = true;
                              this.$Message.loading({
                                content: "正在加载数据,请稍等...",
                                duration: 0,
                              });
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "修改"
                      ),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.copyForm(params.row.id);
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "复制"
                      ),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.delRow(params.row.id);
                            },
                          },
                          style: {
                            textAlign: "center",
                            display: params.row.publicType == '2' && this.buttonRoot != "1003"? 'none' : 'block'
                          },
                        },
                        "删除"
                      ),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.templateType = params.row.templateType;
                              this.publicType = params.row.publicType;
                              this.$Modal.confirm({
                                title: "模板同步设置",
                                width: '430',
                                onOk: () => {
                                  if (this.templateType == "") {
                                    this.$Message.error({
                                      background: true,
                                      content: "模板同步类型为空，设置失败！",
                                    });
                                    return;
                                  }
                                  this.$post(
                                    "/datamsg/api/pc/dynamicform/syncFormAsTemplate",
                                    {
                                      formId: params.row.id,
                                      templateType: this.templateType,
                                      publicType: this.templateType == '1' ? '0' : this.publicType,
                                      orgCode: this.templateType == '2' && this.publicType == '1' ?  parent.vue.loginInfo.userinfo.orgCode : '',
                                      custGlobalId: this.templateType == '1' ?  parent.vue.loginInfo.userinfo.custGlobalId : ''
                                    }
                                  ).then((res) => {
                                    if (res.code == 200) {
                                      this.$Message.success({
                                        background: true,
                                        content: "模板同步成功",
                                      });
                                    } else {
                                      this.$Message.error({
                                        background: true,
                                        content: res.desc,
                                      });
                                    }
                                  });
                                },
                                render: (h) => {
                                  return h("div", [
                                    h(
                                      "RadioGroup",
                                      {
                                        props: {
                                          value: this.templateType,
                                        },
                                        on: {
                                          "on-change": (val) => {
                                            this.templateType = val;
                                          },
                                        },
                                      },
                                      [
                                        h(
                                          "Radio",
                                          {
                                            props: {
                                              label: "1",
                                            },
                                          },
                                          "私有模板"
                                        ),
                                        h(
                                          "Radio",
                                          {
                                            props: {
                                              label: "2",
                                            },
                                          },
                                          "公开模板"
                                        ),
                                      ]
                                    ),

                                    h("div", {
                                      style: {
                                            display:
                                              this.templateType == "2"
                                                ? "inline-block"
                                                : "none",
                                              marginLeft: '6px'
                                          },
                                    },[
                                      h('span',{
                                        style: {
                                          color: '#aaa'
                                        }
                                      },'公开类型：'),
                                      h(
                                        "RadioGroup",
                                        {
                                          props: {
                                            value: this.publicType,
                                          },
                                          on: {
                                            "on-change": (val) => {
                                              this.publicType = val;
                                            },
                                          },
                                        },
                                        [
                                          h(
                                            "Radio",
                                            {
                                              props: {
                                                label: "1",
                                              },
                                            },
                                            "机构"
                                          ),
                                          h(
                                            "Radio",
                                            {
                                              props: {
                                                label: "2",
                                              },
                                            },
                                            "平台"
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]);
                                },
                              });
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "模板同步"
                      ),
                    ]
                  ),
                ]
              ),
            ]);
          },
        },
      ],
      tableData: [],
      loading: false,
      page: 1,
      total: 0,
      pageSize: 20,

      //预览
      previewStatus: false,
      previewFormData: {},
      previeswTitle: "",
      formDetailVo2: {},

      //   新增/修改
      addStatus: false,
      changeStatus: false,
      cancelModelTip: true, //true代表关闭弹窗提示信息
      infoId: "",

      // 同步模板
      templateType: "",
      publicType: ''
    };
  },
  methods: {
    // 删除
    delRow(id) {
      this.$Modal.confirm({
        title: "温馨提示",
        content: "<p>您正在删除表单模板，此操作不可逆，是否继续？</p>",
        onOk: () => {
          this.$get("/datamsg/api/pc/dynamicform/deleteFormById", {
            formId: id,
          })
            .then((res) => {
              if (res.code == 200) {
                this.$Message.success({
                  background: true,
                  content: "删除成功！",
                });
                this.hadlePageSize({
                  page: this.page,
                  pageSize: this.pageSize,
                });
              } else {
                this.$Message.error({
                  background: true,
                  content: res.desc,
                });
              }
            })
            .catch((err) => {
              this.$Message.error({
                background: true,
                content: "删除失败！",
              });
            });
        },
      });
    },
    // 复制
    copyForm(id) {
      this.$Message.loading("正在复制表单信息,请稍等...");
      this.$get("/datamsg/api/pc/dynamicform/selectFormDetail", {
        formId: id,
      }).then((res) => {
        this.$Message.destroy();
        if (res.code == 200 && res.data) {
          res.data.id = this.$core.randomString();
          res.data.interactionFormVos.map((item) => {
            item.fieldId = this.$core.randomString();
            if (item.fieldParamVos) {
              item.fieldParamVos.map((subItem) => {
                subItem.fieldId = this.$core.randomString();
                subItem.id = this.$core.randomString();
              });
            }
            if (item.list) {
              item.list.map((subItem) => {
                subItem.field = this.$core.randomString();
              });
            }
          });
          this.formDetailVo2 = res.data;
          this.addStatus = true;
        } else {
          this.$Message.error({
            background: true,
            content: res.desc,
          });
        }
      });
    },
    //   新增
    addStatusFn(status) {
      if (!status && this.cancelModelTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
          onOk: () => {
            this.addStatus = status;
          },
        });
      } else {
        this.addStatus = status;
      }
    },
    //   修改
    changeStatusFn(status) {
      if (!status && this.cancelModelTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
          onOk: () => {
            this.changeStatus = status;
          },
        });
      } else {
        this.changeStatus = status;
      }
    },
    // 保存成功后执行
    saveSuccess() {
      this.cancelModelTip = false;
      this.addStatusFn(false);
      this.changeStatusFn(false);
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize,
      });
    },
    // 新增/修改保存
    saveAddChange(Form) {
      this.cancelModelTip = false;
      this.$refs[Form].save();
    },
    //   预览
    previewStatusFn(status) {
      this.previewStatus = status;
    },
    previewForm(id) {
      this.$Message.loading("正在加载数据,请稍等...");
      this.$get("/datamsg/api/pc/dynamicform/selectFormDetail", {
        formId: id,
      }).then((res) => {
        this.$Message.destroy();
        if (res.code == 200 && res.data) {
          this.previewFormData = res.data;
          this.previeswTitle = res.data.formTitle;
          this.previewStatus = true;
        } else {
          this.$Message.error({
            background: true,
            content: res.desc,
          });
        }
      });
    },
    search() {
      this.hadlePageSize({
        page: 1,
        pageSize: this.pageSize,
      });
    },
    hadlePageSize(obj) {
      this.loading = true;
      this.$get("/datamsg/api/pc/dynamicform/selectFormPage", {
        page: obj.page,
        pageSize: obj.pageSize,
        ...this.searchForm,
        templateType: this.searchForm.templateType == "1" ? "1" : "2",
        custGlobalId: this.searchForm.templateType == "1" ? parent.vue.loginInfo.userinfo.custGlobalId : '',
        publicType:
          this.searchForm.templateType == "3"
            ? "1"
            : this.searchForm.templateType == "4"
            ? "2"
            : '0',
        orgCode:
          this.searchForm.templateType == "3"
            ?  parent.vue.loginInfo.userinfo.orgCode
            : null,
      })
        .then((res) => {
          if (res.code == 200 && res.dataList) {
            res.dataList.map((item) => {
              item.gmtCreate = this.$core.formatDate(
                new Date(item.gmtCreate),
                "yyyy-MM-dd hh:mm:ss"
              );
            });
            this.tableData = res.dataList;
            this.page = res.currentPage;
            this.pageSize = res.pageSize;
            this.total = res.maxCount;
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
          this.loading = false;
        })
        .catch((err) => {
          this.$Message.error({
            background: true,
            content: "获取数据失败",
          });
        });
    },
  },
  created() {
    let userButtonRoot = parent.vue.userButtonRoot;
    this.buttonRoot = userButtonRoot.includes("1001")
      ? "1001"
      : userButtonRoot.includes("1002")
      ? "1002"
      : userButtonRoot.includes("1003")
      ? "1003"
      : "";
    console.log(parent.vue.userButtonRoot)
    this.hadlePageSize({
      page: this.page,
      pageSize: this.pageSize,
    });
  },
  components: {
    LiefengContent,
    LiefengModal,
    LiefengTable,
    PreviewForm,
    AddForm,
    ChangeForm,
  },
};
</script>
    
<style scoped lang='less'>
.add {
  /deep/.ivu-icon-ios-close {
    display: none;
  }
}
/deep/#toolsbarRight {
  width: 1000%;
  .searchForm {
    .ivu-form-item {
      margin-bottom: 0;
    }
  }
}
</style>